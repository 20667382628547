import { IWinzoBannerBottomComponentHomePageProps } from '../../../lib/types/components';
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import CommonWithdrawalPartnerComponentV2 from '../CommonWithdrawalPartner/CommonWithdrawalPartnerComponentV2';

const WinzoBannerBottomComponentHomePageV2 = (props: IWinzoBannerBottomComponentHomePageProps) => {
    const {
        bannerBottomData,
        withdrawalPartnerData
    } = props;

    return (
        bannerBottomData && Object.keys(bannerBottomData).length?
            <div className="jsxcontainer container-fluid">
                <style jsx>{`
                  .jsxcontainer {
                    background-color: #3c0767;
                    padding-bottom: 4.6vw;
                    color: white;
                    font-family: var(--font-primary);
                  }

                  .innerContainer {
                    padding: 8px 0;
                  }
                  
                  .textParent{
                    padding: 0;
                  }

                  .text1 {
                    margin: 0;
                    font-size: ${dynamicCssSize(38, 14)};
                    font-weight: 600;
                    font-style: italic;
                    letter-spacing: 1.71px;
                    text-align: center;
                  }

                  .text2 {
                    margin: 0;
                    font-size: ${dynamicCssSize(22, 10)};
                    font-weight: 500;
                    text-align: center;
                  }

                  .seperatorLine {
                    width: 2.1px;
                    background: linear-gradient(rgba(200, 200, 199, 0), #B4B4B5, rgba(151, 151, 151, 0));
                  }

                  .partnerContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                  }

                  @media screen and (max-width: 576px) {
                    .jsxcontainer {
                      display: none;
                    }
                  }
                `}
                </style>
                <div className="innerContainer container">
                    <div className={"row"}>
                        <div className={"col"}>
                            <div className="textContainer row">
                                <div className={"col textParent"}>
                                    <div>
                                        <p className="text1">{bannerBottomData.text1}</p>
                                    </div>
                                    <div>
                                        <p className="text2">{bannerBottomData.text2}</p>
                                    </div>
                                </div>
                                <div className={"col textParent"}>
                                    <div>
                                        <p className="text1">{bannerBottomData.text3}</p>
                                    </div>
                                    <div>
                                        <p className="text2">{bannerBottomData.text4}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="seperatorLine"></div>
                        <div className="col partnerContainer">
                            <CommonWithdrawalPartnerComponentV2 withdrawalPartnerData={withdrawalPartnerData}
                                                              isInBanner={true}/>
                        </div>
                    </div>
                </div>
            </div>
            :
            <></>
    )
}

export default WinzoBannerBottomComponentHomePageV2;