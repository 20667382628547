import { IBannerDownloadButtonComponentProps, IFixedDownloadButtonProps } from "../../../lib/types/components";
import { BANNER_DOWNLOAD_BUTTON_TYPE, GET_APP_FB_URL, GET_APP_URL } from "../../../lib/utils/constants/GlobalConstants";
import { currentPageURL } from "../../../lib/utils/constants/PagesConstants";
import DisclaimerComponent from "../DisclaimerComponent/DisclaimerComponent";
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";

const BannerDownloadButtonComponentV2 = (props: IBannerDownloadButtonComponentProps) => {
    const {
        apkUrl,
        isApkDownloading,
        isUtmHotstar,
        fixedDownloadButtonData,
        sendAnalytics,
        setIsApkDownloading,
        setIsToastOpen,
        bgColor="#210131",
        iosCtaData,
        abTestingData,
        appVersions,
    } = props;

    const isGetAppFb: boolean = currentPageURL === GET_APP_FB_URL;
    const isGetApp: boolean = currentPageURL === GET_APP_URL;

    const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
        apkUrl: apkUrl,
        isApkDownloading: isApkDownloading,
        type: BANNER_DOWNLOAD_BUTTON_TYPE,
        fixedDownloadButtonData: fixedDownloadButtonData,
        isUtmHotstar: isUtmHotstar,
        sendAnalytics: sendAnalytics,
        setIsApkDownloading: setIsApkDownloading,
        setIsToastOpen: setIsToastOpen,
        iosCtaData: iosCtaData,
        abTestingData,
        appVersions,
    }
    return (
        <>
            <style jsx>{`
              .mainContainer {
                display: none;
                font-family: var(--font-primary);
              }

              @media screen and (max-width: 767px) {
                .mainContainer {
                  padding: 13px 15px 15px;
                  background-color: ${bgColor};
                  display: block !important;
                }

                .content {
                  font-size: 17px;
                  font-weight: 600;
                  font-style: italic;
                  letter-spacing: -0.32px;
                  text-align: center;
                  color: #fff;
                  margin-bottom: 10px;
                  text-transform: uppercase;
                }
              }

              @media screen and (max-width: 576px) {
                .mainContainer {
                  display: block !important;
                }
              }
            `}
            </style>
            <div className={"container-fluid mainContainer"}>
                {
                    <>
                        <div style={isGetAppFb ? {padding: "0 0 10px 0"} : {padding: "0"}}
                             className={"container-fluid"}>
                            {/* <div className={"content"}>{props.blinkTextDownloadButton}</div> */}
                            <FixedDownloadButtonV2 {...fixedDownloadBtnProps} />
                            {
                                isGetApp && isUtmHotstar && <DisclaimerComponent/>
                            }

                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default BannerDownloadButtonComponentV2;
